@import "/src/styles/colors";

.techStatus{
  width: 110px;
}
.state {
  border-radius: 5px;
  max-width: 85px;
  text-transform: capitalize;
  a {
    width: 90px;
    display: block;
    text-align: center;
    margin: 0;
    padding-left: 18px;
    padding-right: 18px;
    color: $color-content-bg !important;
  }
}

.state-available {
  a {
    background: $color-semi-blue
  }
}
.state-sold {
  a {
    background: $color-gray;
  }
}

.state-active {
  a {
    background: $color-green;
  }
}

.state-installed {
  a {
    background: $color-green-faintly
  }
}

.state-error {
  a {
    background: $color-red;
  }
}
.state-removed {
  a {
    background: $color-orange;
  }
}

.icon{
  font-size: 20px;
  margin-right: 5px;
  vertical-align: text-bottom;
}