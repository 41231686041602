.chart{
  padding-right: 30px;
}
.head {
  padding: 27px 0 35px 30px;
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
  .controls {
    .button {
      border-radius: 4px;
      margin-left: 16px;
    }
  }
}