@import "/src/styles/colors";

.status {
  border-radius: 5px;
  width: max-content;
  text-transform: capitalize;
  a {
    margin: 0;
    padding-left: 18px;
    padding-right: 18px;
    color: $color-content-bg !important;
  }
}

.status-released {
  a {
    background: blue; //$color-link-blue;
  }
}

.status-enabled {
  a {
    background: green; //$color-link-blue;
  }
}

.status-disabled {
  a {
    background: red; //$color-link-blue;
  }
}