@import "/src/styles/colors";

.techStatus {
  width: 110px;
}
.status {
  border-radius: 5px;
  width: 85px;
  text-transform: capitalize;
  a {
    width: 90px;
    display: block;
    text-align: center;
    margin: 0;
    padding-left: 18px;
    padding-right: 18px;
    color: $color-content-bg !important;
  }
}

.status-available {
  a {
    background: $color-gray
  }
}

.status-paid {
  a {
    background: #0296db;
  }
}

.status-installed {
  a {
    background: #0fa431
  }
}

.status-disabled {
  a {
    background: #f04444;
  }
}

.icon{
  vertical-align: text-bottom;
}