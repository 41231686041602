@import "/src/styles/colors";

.status {
  border-radius: 5px;
  width: max-content;
  text-transform: capitalize;

  a {
    width: 103px;
    display: block;
    text-align: center;
    margin: 0;
    padding-left: 18px;
    padding-right: 18px;
    color: $color-content-bg !important;
    background: $color-text-secondary;
  }
}

.status-authorized {
  a {
    background: $color-green;
  }
}

.status-pending {
  a {
    background: $color-gray;
  }
}

.status-refunded {
  a {
    background: $color-red;
  }
}

.status-canceled {
  a {
    background: $color-dark-blue;
  }
}

.status-declined {
  a {
    background: $color-orange;
  }
}

.number {
  width: 40px;
}

.product {
  width: 350px;
}

.user {
  max-width: 400px;
  width: 250px;
  word-break: break-all;
}

.width50 {
  width: 100px;
}

.thDate {
  width: 150px;
}

.green {
  color: $color-green;
}