$color-text-primary: #061027;
$color-text-secondary: #868686;
$color-text-disabled: #b2b2b2;
$color-main-bg: #F3F4F6;
$color-sidebar-bg: #003A64;
$color-content-bg: #fff;
$color-light-gray: rgba(0, 0, 0, 0.02);
$color-gray-bg: rgba(0, 0, 0, 0.07);
$color-divider: rgba(0, 0, 0, 0.07);
$color-hover-bg: $color-light-gray;
$color-dark-blue: #2031a8;
$color-accent: #2254f5;
$color-light-blue: #4090f7;
$color-pale-blue: #9ec4fa;
$color-white: #FFFFFF;
$color-black: #000000;
$color-blue: #1890FF;
$color-semi-blue: #5d9cec;
$color-gray: #bebebe;
$color-low: #fffc18;
$color-orange-min: #ffbf00;
$color-orange: #ff8400;
$color-purple: #D12EA2;
$color-red: #c15353;
$color-assets-blue: #0097EC;
$color-green: #00a400;
$color-green-faintly: #6ba26b;
$accent-color: #3AB696;