.wrapper-dashboard-page{
  max-width: 1330px;
  margin: 0 auto;
}
.cards-wrapper.container {
  background: unset;
  box-shadow: unset;
  overflow: inherit;
}
.dashboard-header.container {
  background: unset;
  box-shadow: unset;
  margin-top: 44px;
  margin-bottom: 44px;
  h1{
    color:#AAAAAA;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
  }
  .desc{
    color:#AAAAAA!important;
    font-size: 18.5px;
    font-weight: normal;
  }
}


.container.charts {
  background: unset;
  box-shadow: unset;

  .wrapper-chart {
    &.first {
      margin-right: 20px;
    }

    &.second {
      margin-left: 20px;
    }

    .chart {
      padding-right: 30px;
    }

    background-color: #fff;
    height: 292px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
}