@import "/src/styles/colors";

.icon{
  font-size: 19px !important;
  padding-right: 5px;
  vertical-align: middle;
}
.gray{
  color:$color-gray;
}
.green{
  color: $color-green;
}