@import 'src/styles/colors';
.cards {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  height: 145px;
  .green {
    color: $color-green !important;
  }
  .blue {
    color: $color-semi-blue !important;
  }

  .orange {
    color: $color-orange !important;
  }

  .purple {
    color: $color-purple !important;
  }
  .gray {
    color: $color-gray !important;
  }
}