@import "./colors";

button.ant-modal-close {
  display: none;
}


.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.ant-modal-root {
  .ant-modal-footer {
    padding: 0 20px 20px;
  }

  .ant-modal-footer, .ant-modal-header {
    border-top: none;
    border-bottom: none;
  }

  .ant-modal-footer {
    padding: 30px;
  }

  .ant-modal-header {
    padding: 36px 30px 10px;
  }

  .ant-modal-body {
    padding: 30px 30px 10px
  }

  .ant-modal-title {
    font-size: 23px;
    font-weight: 400;
  }
}