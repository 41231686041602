@import "antd/dist/antd.min.css";
@import "colors";
@import "ant";

body {
  margin: 0;
  background-color: #f9f9f9 !important;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 12px;
  color: $color-text-primary;
  line-height: normal;
  display: block;

  ul {
    list-style-type: none;
  }
}

body #esim-admin {
  line-height: normal;
  font-family: "Open Sans", sans-serif !important;
  .disabled{
    color:$color-gray!important;
  }

  h2 {
    margin-bottom: 0.5em;
  }

  ul {
    list-style-type: none;
  }
  .no-wrap{
    white-space: nowrap;
  }

  a.link {
    color: $color-black;

    &:hover {
      color: $color-blue;
    }

    text-decoration: underline;
  }

  .esim-detail{
    .admin-details-head .header h1{
      text-transform: none;
    }
  }
}

