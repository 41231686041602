@import "/src/styles/colors";

.packageStatus{
  vertical-align: middle;
}

.detailTitle{
  .header{
    h1{
      text-transform:none;
    }
  }
}